import React from "react";

import { Icon, ScrollBox, Tooltip, isEmpty } from "@omnichat/arm_ui_kit";

import { Select } from "../../../../Components";

import CategoryItem from "./CategoryItem";
import { MinCategoryListOffsetTop } from "./Consts";
import { useSetOffsetTop } from "../../../../ReactFeatures/Common/Hooks/useSetOffsetTop";

import * as s from "./styles.module.scss";

/**
 * Модель данных
 */
export interface ICategoryListProps {
  skillGroups: {
    value: number | string;
    label: string;
  }[];
  skillGroup: number;
  categories: {
    id: number | string;
    name: string;
    current: boolean;
    editable: boolean;
  }[];
  onSelectSkillgroup: (id: number | string) => void;
  onSelectCategory: (id: number | string) => void;
  onEditCategory: (id?: number | string) => void;
  onNextPage: () => void;
  onAdd: () => void;
}

/**
 * Компонент списка категорий
 */
const CategoryList = ({
  skillGroups,
  categories,
  onSelectCategory,
  onEditCategory,
  onAdd,
  onSelectSkillgroup,
  skillGroup,
  onNextPage
}: ICategoryListProps): JSX.Element => {
  const { el: categoryListOffsetTop, ref: categoryHeaderRef } = useSetOffsetTop(
    MinCategoryListOffsetTop
  );

  const getValue = (value, options) => {
    return value ? [options.find((o) => o.value === value)] : [];
  };

  return (
    <div className={s["categories"]}>
      <div ref={categoryHeaderRef} className={s["categoriesHeader"]}>
        <div className={s["categoriesHeaderBtnWrap"]}>
          <h4 className={s["categoriesTitle"]}>Категории</h4>
          <Tooltip
            content="Добавить категорию"
            position="top"
            modifiers={{ offset: 10 }}
          >
            <div className={s["categoriesAdd"]} onClick={onAdd}>
              <Icon
                color={"blue"}
                name={"plus_bold"}
                width="18px"
                height="18px"
              />
            </div>
          </Tooltip>
        </div>

        <div className={s["categoriesSelect"]}>
          <Select
            placeholder="Все группы"
            options={skillGroups}
            selected={getValue(skillGroup, skillGroups)}
            onSelectOption={([selected]) =>
              onSelectSkillgroup(Number(selected.value))
            }
          />
        </div>
      </div>
      <div className={s["categoriesList"]}>
        <ScrollBox
          autoHeight
          hasScrollControlBackground
          onPaginationBottom={() => onNextPage()}
        >
          <div
            style={{ marginTop: categoryListOffsetTop }}
            className={s["categoriesListContentBlock"]}
          >
            {!isEmpty(categories) &&
              categories.map(({ id, name, current, editable }, i) => (
                <CategoryItem
                  key={i}
                  id={id}
                  name={name}
                  current={current}
                  editable={editable}
                  onSelect={(id) => onSelectCategory(id)}
                  onEdit={(id) => onEditCategory(id)}
                />
              ))}
          </div>
        </ScrollBox>
      </div>
    </div>
  );
};

export default CategoryList;
