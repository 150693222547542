import { takeEvery, put } from "redux-saga/effects";
import {
  sendKeydownEvent,
  sendMouseMoveEvent
} from "../Store/SystemStatistics.reducer";
import {
  sendUserEvent,
  stompConnected,
  sendPongEvent,
  incomingPingRoutine
} from "../../../Stomp/Stopm.actions";

function* sendStatisticEvent(action) {
  yield put(
    sendUserEvent.trigger({
      user_id: window.core.user.id,
      action: action.type,
      data: {}
    })
  );
}

function* initStatisticEvent() {
  yield put(
    sendUserEvent.trigger({
      user_id: window.core.user.id,
      action: "WEBSOCKET_CONNECTED",
      data: {}
    })
  );
}

function* sendPongSaga({ payload }) {
  const { body } = payload;

  const data = yield JSON.parse(body);

  yield put(
    sendPongEvent.trigger({
      ...data,
      action: "pong",
      user_id: window.core.user.id
    })
  );
}

export default function*() {
  yield takeEvery([stompConnected], initStatisticEvent);
  yield takeEvery([sendKeydownEvent, sendMouseMoveEvent], sendStatisticEvent);
  yield takeEvery([incomingPingRoutine], sendPongSaga);
}
