import React, { FC, useState } from "react";
import c from "classnames";

import { Button } from "@omnichat/arm_ui_kit";

import { ModalHeader, FormField } from "../../../../Components";
import TextArea from "../../../../Components/TextArea/TextArea";

import * as cs from "../../../../styles/common.module.scss";
import * as s from "./styles.module.scss";

interface ICloseAppealProps {
  comment?: boolean;
  onCancelAction?: () => void;
  onConfirmAction?: (text: string) => string | void;
}

const dashboardCloseMessage = `После закрытия обращения, клиенту будет предложено выставить оценку качества обслуживания в чате.`;
const chatCloseMessage = `После закрытия обращения Вы не сможете написать в него сообщение, а
                          клиенту будет отправлен запрос оценки качества обслуживания.`;

const CloseAppealByAgentWithoutClassifierModal: FC<ICloseAppealProps> = ({
  comment = false,
  onCancelAction = () => {},
  onConfirmAction = () => {}
}) => {
  const [newComment, setComment] = useState("");
  const [error, setError] = useState("");

  const onConfirmActionWithComment = () => {
    if (!newComment?.trim()) {
      setError("Комментарий обязателен");
      return false;
    }
    onConfirmAction(newComment);
  };

  return (
    <div
      id="CloseAppealByAgentWithoutClassifierModal"
      className={c(cs["defaultModal"], s["modal"])}
    >
      <ModalHeader>Закрыть обращение?</ModalHeader>
      <div className={c(cs["defaultModalContent"], s["modalContent"])}>
        {comment ? dashboardCloseMessage : chatCloseMessage}
      </div>

      {comment && (
        <FormField label={"Причина"} required={true}>
          <TextArea
            focus="Введите комментарий к закрытию обращения"
            placeholder="Введите комментарий к закрытию обращения"
            onChange={(e) => {
              setComment(e.text);
              setError("");
            }}
            isError={!!error}
            actionText={error}
            initialValue=""
          />
        </FormField>
      )}

      <div className={cs["defaultModalControl"]}>
        <Button
          id="cancelButton"
          type="default"
          theme="red"
          text="Отменить"
          onClick={onCancelAction}
        />
        <Button
          id="confirmButton"
          type="default"
          theme="green"
          text="Продолжить"
          onClick={comment ? onConfirmActionWithComment : onConfirmAction}
        />
      </div>
    </div>
  );
};

export default CloseAppealByAgentWithoutClassifierModal;
