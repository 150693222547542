import React, { FC } from "react";
import Viewer from "react-viewer";

/**
 * Свойства компонента для просмотра изображения.
 *
 * @prop {boolean} isViewerShown Флаг, который показывает необходимость
 * отображения компонента для просмотра изображения.
 * @prop {string} imageSrc Ссылка изображение.
 * @prop {Function} onClose Колбэк на закрытие компонента просмотра.
 * @prop {HTMLElement} [container] Контэйнер для встраивания.
 * @prop {number} [zIndex] z-index элемента.
 */
interface IImageViewerProps {
  isViewerShown: boolean;
  imageSrc: string;
  onClose: () => void;
  container?: HTMLElement;
  zIndex?: number;
  extraClass?: (string | object)[];
}

/**
 * Компонент для просмотра изображения.
 */
const ImageViewer: FC<IImageViewerProps> = ({
  isViewerShown,
  imageSrc,
  onClose,
  container,
  zIndex = 101
}): JSX.Element => (
  <Viewer
    visible={isViewerShown}
    zIndex={zIndex}
    zoomSpeed={0.2}
    onClose={onClose}
    images={[{ src: imageSrc }]}
    rotatable
    changeable={false}
    attribute={false}
    noNavbar
    loop={false}
    container={container}
    disableKeyboardSupport
  />
);

export default ImageViewer;
