import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "frontend/src/ReactCore/store/types";
import { GET_APPEALS } from "./Appeals.reducer";
import { isEmpty } from "@omnichat/arm_ui_kit";

export const appealsState = (state: StoreState.State) => {
  return state.Appeals;
};

export const filtersState = (state: StoreState.State) => {
  return state.DashboardFilters.formData;
};

export const appeals = createSelector(appealsState, (state) => {
  return state.appeals;
});

export const loading = createSelector(appealsState, (state) => {
  return !isEmpty(state.loading);
});

export const appealsLoading = createSelector(appealsState, (state) => {
  return state.loading.includes(GET_APPEALS);
});

export const page = createSelector(appealsState, (state) => {
  return state.page;
});

export const isLastPageReached = createSelector(appealsState, (state) => {
  return state.lastPage;
});

export const channelsList = createSelector(appealsState, (state) => {
  return state.channels;
});
