import { DashboardQueryParams } from "./DashboardSection";
import { AppealFiltersData } from "../Appeals/Appeals";
import { ChartsFilters } from "../Charts/Charts";
import { getUTCTimestampFromLocalTimestamp } from "../../ReactFeatures/Common/Utils/DateAndTime.utils";

export const parseQueryToAppealFilter = (
  query: DashboardQueryParams
): AppealFiltersData => {
  const result = {} as AppealFiltersData;
  const map = {
    dateStart: getUTCTimestampFromLocalTimestamp(Number(query.date_start)),
    dateEnd: getUTCTimestampFromLocalTimestamp(Number(query.date_end)),
    status: query.status === "any" ? undefined : query.status,
    csi: query.csi === "any" ? undefined : query.csi,
    sl: query.sl === "any" ? undefined : query.sl,
    skillGroupId: query.skill === "all" ? undefined : query.skill,
    channelId: query.channel === "any" ? undefined : query.channel,
    mrf: query.mrf === "any" || query.mrf === "" ? undefined : query.mrf,
    rf:
      query.rf === "any" || query.rf === ""
        ? undefined
        : query.rf === "none"
        ? "0"
        : query.rf,
    regionId:
      query.region === "any" || query.region === ""
        ? undefined
        : query.region === "none"
        ? "0"
        : query.region,
    client: query.client === "" ? undefined : query.client,
    agentId: query.agent === "any" ? undefined : query.agent,
    whoProcessed:
      query.who_processed === "any" ? undefined : query.who_processed,
    remarkPhone: query.remark_phone === "" ? undefined : query.remark_phone,
    remarkBirthday:
      query.remark_birthday === "" || query.remark_birthday === "any"
        ? undefined
        : query.remark_birthday
            ?.split(".")
            .reverse()
            .join("-"),
    remarkFio: query.remark_fio === "" ? undefined : query.remark_fio,
    withoutThemes: query.without_themes === "1" ? query.without_themes : false
  };

  for (let i in map) {
    if (map[i] !== undefined) {
      result[i] = map[i];
    }
  }
  return result;
};

export const parseQueryToChartsFilter = (
  query: DashboardQueryParams
): ChartsFilters => {
  return {
    date_start: query.date_start || "any",
    date_end: query.date_end || "any",
    client: query.client || "any",
    mrf_code: query.mrf || "any",
    csi: query.csi || "any",
    sl: query.sl || "any",
    remark_birthday: query.remark_birthday || "any",
    remark_fio: query.remark_fio || "any",
    remark_phone: query.remark_phone || "any",
    status: query.status || "any",
    skill: query.skill || "any",
    who_processed: query.who_processed || "any",
    agent: query.agent || "any",
    channel: query.channel || "any",
    rf_id: query.rf || "any",
    region_id: query.region || "any",
    without_themes: query.without_themes === "1" ? "1" : "any"
  };
};

export const parseParamsToUrl = (params: DashboardQueryParams): string => {
  return new URLSearchParams(params).toString();
};
