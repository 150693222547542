import React, { FC, memo, useRef, useState, useEffect } from "react";
import { isEmpty } from "@omnichat/arm_ui_kit";

import {
  Select as MuiSelect,
  MenuItem,
  SelectChangeEvent,
  Box,
  Typography
} from "@mui/material";

import Chip from "../Chip";
import FormField from "../MuiFormField/FormField";

import {
  getPreparedValue,
  getPreparedOptions,
  getPreparedValueToOptions,
  getHashId,
  hasValue
} from "./utils";
import { ISelectProps, ISelectOption } from "./Select.d";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 500
    }
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null,
  disableAutoFocusItem: true
};

const Select: FC<ISelectProps> = ({
  id,
  name,
  options: propOptions = [],
  selected = [],
  onOpen,
  onSelectOption = (): void => {},
  placeholder = "Не указано",
  isMulti = false,
  isError = false,
  disabled = false,
  actionText,
  extraSX,
  label,
  required,
  labelCaseStyle = "none"
}) => {
  const ref = useRef(null);
  const value2Option = getPreparedValueToOptions(propOptions);
  const options = getPreparedOptions(isMulti, propOptions);
  const value = getPreparedValue(isMulti, selected);
  const hashId = getHashId({ actionText, label, placeholder });
  const labelBoxSX = extraSX?.labelBox ?? {};

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!disabled) {
      return;
    }

    setOpen(false);
  }, [disabled]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (
    e: SelectChangeEvent<string | number | (string | number)[]>
  ): void => {
    if (disabled) {
      return;
    }

    setOpen(true);
    onOpen?.();
    ref.current.focus();
  };

  const handleChange = (
    e: SelectChangeEvent<string | number | (string | number)[]>
  ): void => {
    ref.current.blur();
    if (Array.isArray(e.target.value)) {
      const newValue = e.target.value.map(
        (optionValue) => value2Option[optionValue]
      );
      onSelectOption(newValue);
    } else {
      onSelectOption([value2Option[e.target.value]]);
    }
  };

  const handleDeleteChip = (value: ISelectOption["value"]): void => {
    onSelectOption(selected?.filter((s) => s.value !== value));
  };

  return (
    <Box display="flex" flexDirection="column">
      <FormField
        label={label || ""}
        labelCaseStyle={labelCaseStyle}
        required={required}
        disabled={disabled}
        actionText={actionText}
        isError={isError}
        inputId={hashId}
        extraSX={labelBoxSX}
      >
        <MuiSelect
          ref={ref}
          id={id}
          name={name}
          fullWidth
          displayEmpty
          error={isError}
          multiple={isMulti}
          onOpen={handleOpen}
          onClose={handleClose}
          open={open}
          value={value || ""}
          onChange={handleChange}
          variant="standard"
          disabled={disabled}
          disableUnderline
          sx={[{ pt: 0 }, extraSX]}
          renderValue={(value) => (
            <>
              {!hasValue(value) ? (
                <span>{placeholder}</span>
              ) : (
                <>
                  {Array.isArray(value) && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {value.map((v) => (
                        <Chip
                          key={v}
                          onMouseDown={(e) => e.stopPropagation()}
                          label={value2Option[String(v)]?.label}
                          onDelete={() => handleDeleteChip(v)}
                        />
                      ))}
                    </Box>
                  )}

                  {!Array.isArray(value) && (
                    <>{value2Option[String(value)]?.label}</>
                  )}
                </>
              )}
            </>
          )}
          MenuProps={MenuProps}
        >
          {!isEmpty(options) ? (
            options.map(
              (option) =>
                !option.disabled && (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )
            )
          ) : (
            <Typography ml={2} variant="body1" sx={{ color: "text.primary" }}>
              Не найдено ни одного значения
            </Typography>
          )}
        </MuiSelect>
      </FormField>
    </Box>
  );
};

export default memo(Select);
