import React, { FC, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { isEmpty, Preloader } from "@omnichat/arm_ui_kit";
import { useActions } from "./Store/Appeals.reducer";
import {
  appeals,
  loading,
  appealsLoading,
  page,
  isLastPageReached,
  channelsList
} from "./Store/Appeals.selector";

import { parseQueryToAppealFilter } from "../DashboardSection/utils";
import { DashboardQueryParams } from "../DashboardSection/DashboardSection";

import useQuery from "../../ReactFeatures/Common/Hooks/useQuery";

import Table from "../../Components/Table";
import { CommonItem, CommonType } from "./Components/AppealsItems/CommonItem";

import { getStatusName, getUserInfo, getRating } from "./Utils";
import { getMessageTime } from "../../ReactFeatures/MessageBox/Utils";

import * as styles from "./style.module.scss";

export enum Columns {
  id = "ID",
  agent = "AGENT",
  date = "DATE",
  csi = "CSI",
  client = "CLIENT"
}

export type RowType = {
  [key in Columns]: CommonType;
};

type Props = {
  onOpenAppeal: (id: string) => void;
};

const Appeals: FC<Props> = ({ onOpenAppeal }) => {
  const { getAppeals, resetAppealList, getChannels } = useActions();
  const { query } = useQuery<DashboardQueryParams>();
  const isLoading = useSelector(loading);
  const isAppealsLoading = useSelector(appealsLoading);
  const appealsList = useSelector(appeals);
  const currentPage = useSelector(page);
  const isLastPage = useSelector(isLastPageReached);
  const channels = useSelector(channelsList);

  useEffect(
    () => () => {
      resetAppealList();
    },
    []
  );

  useEffect(() => {
    if (!isEmpty(query)) {
      resetAppealList();

      getChannels();
      getAppeals({
        page: 0,
        filters: parseQueryToAppealFilter(query)
      });
    }
  }, [query]);

  const columns = [
    {
      field: Columns.id,
      headerName: "Номер",
      customHeader: (data) => <div>{data}</div>,
      customColumn: (data: CommonType) => <CommonItem {...data} />
    },
    {
      field: Columns.agent,
      headerName: "Агент",
      customHeader: (data) => <div>{data}</div>,
      customColumn: (data: CommonType) => <CommonItem {...data} />
    },
    {
      field: Columns.date,
      headerName: "Дата поступления",
      customHeader: (data) => <div>{data}</div>,
      customColumn: (data: CommonType) => <CommonItem {...data} />
    },
    {
      field: Columns.csi,
      headerName: "Оценка CSI",
      customHeader: (data) => <div>{data}</div>,
      customColumn: (data: CommonType) => <CommonItem {...data} />
    },
    {
      field: Columns.client,
      headerName: "Клиент",
      customHeader: (data) => <div>{data}</div>,
      customColumn: (data: CommonType) => <CommonItem {...data} />
    }
  ];

  const rows: RowType[] =
    appealsList.map((appeal) => ({
      [Columns.id]: {
        id: appeal.id,
        label: `#${appeal.id}`,
        value: getStatusName(appeal.status),
        center: true
      },
      [Columns.agent]: getUserInfo(appeal),
      [Columns.date]: {
        label: getMessageTime(appeal.dateStart * 1000),
        center: true
      },
      [Columns.csi]: {
        label: getRating(appeal),
        center: true
      },
      [Columns.client]: {
        label: appeal.clientName,
        value: channels?.find((c) => c.id === appeal.channelId)?.name,
        icon: channels?.find((c) => c.id === appeal.channelId)
          ?.channelTypeAlias,
        center: true
      }
    })) || [];

  const handleRowClick = (row: RowType) => {
    onOpenAppeal(`${row[Columns.id].id}`);
  };

  const nextPage = useCallback(
    debounce(() => {
      if (!isLoading && !isLastPage) {
        console.log(isLoading, isLastPage, currentPage);
        getAppeals({
          page: currentPage + 1,
          filters: parseQueryToAppealFilter(query)
        });
      }
    }, 400),
    [isLoading, isLastPage, currentPage]
  );

  return (
    <>
      {isAppealsLoading && (
        <div className={styles["loader"]}>
          <Preloader show />
        </div>
      )}
      {!isEmpty(rows) ? (
        <Table
          columns={columns}
          rows={rows}
          onRowClick={handleRowClick}
          loadMore={nextPage}
        />
      ) : (
        !isAppealsLoading && <div className={styles["empty"]}>Нет данных за выбранный период</div>
      )}
    </>
  );
};
export default Appeals;
