import React from "react";
import { addDays } from "date-fns";

import { Button, DatePicker } from "@omnichat/arm_ui_kit";

import {
  getToday,
  getYesterday,
  getWeekPeriod,
  getMonthPeriod,
  getQuarterPeriod,
  setTime
} from "./utils";

import { DEFAULT_UPPER_TIME_LIMIT, DEFAULT_LOWER_TIME_LIMIT } from "./consts";

import * as s from "./styles.module.scss";

interface IData {
  date_start: Date;
  date_end: Date;
}

/**
 * Модель свойств панели фильтрации.
 *
 * @prop {boolean} [isTimeAvailable] Признак, который показывает доступность проставить время.
 */
interface IPeriod {
  data: IData;
  onChange: (value: object) => void;
  onAlert: (value: string) => string | void;
  isTimeAvailable?: boolean;
  confirmInProgress?: boolean;
}

const Period: React.FunctionComponent<IPeriod> = ({
  data,
  onChange,
  onAlert,
  isTimeAvailable = false,
  confirmInProgress = false
}): JSX.Element => {
  const onChangePeriod = (filedsData: IData): void => {
    onChange({ ...data, ...filedsData });
  };

  /**
   * Установит сегодняшнюю дату.
   */
  const setToday = () => {
    let start: Date = getToday();
    let end: Date = getToday();

    start = setTime(start, DEFAULT_UPPER_TIME_LIMIT);
    end = setTime(end, DEFAULT_LOWER_TIME_LIMIT);

    onChangePeriod({
      date_start: start,
      date_end: end
    });
  };

  /**
   * Установит вчерашнюю дату.
   */
  const setYesterday = () => {
    let start: Date = getYesterday();
    let end: Date = getYesterday();

    start = setTime(start, DEFAULT_UPPER_TIME_LIMIT);
    end = setTime(end, DEFAULT_LOWER_TIME_LIMIT);

    onChangePeriod({
      date_start: start,
      date_end: end
    });
  };

  /**
   * Установит период равный неделе.
   */
  const setWeek = () => {
    const period = getWeekPeriod();

    period.start = addDays(setTime(period.start, DEFAULT_UPPER_TIME_LIMIT), 1); // Потому что с временем получается 8 суток, если не прибавить день
    period.end = setTime(period.end, DEFAULT_LOWER_TIME_LIMIT);

    onChangePeriod({
      date_start: period.start,
      date_end: period.end
    });
  };

  /**
   * Установит период равный месяцу.
   */
  const setMonth = () => {
    const period = getMonthPeriod();

    period.start = setTime(period.start, DEFAULT_UPPER_TIME_LIMIT);
    period.end = setTime(period.end, DEFAULT_LOWER_TIME_LIMIT);

    onChangePeriod({
      date_start: period.start,
      date_end: period.end
    });
  };

  /**
   * Установит период равный кварталу.
   */
  const setQuarter = () => {
    const period = getQuarterPeriod();

    period.start = setTime(period.start, DEFAULT_UPPER_TIME_LIMIT);
    period.end = setTime(period.end, DEFAULT_LOWER_TIME_LIMIT);

    onChangePeriod({
      date_start: period.start,
      date_end: period.end
    });
  };

  const dateValidation = (dates: any) => {
    const quarter = getQuarterPeriod(dates.endDate);
    if (dates.startDate < quarter.start) {
      onAlert && onAlert("Диапазон не может быть больше квартала");
      dates.startDate = quarter.start;
    }
    return dates;
  };

  return (
    <div className={s["filterPanelPeriodWrap"]}>
      <div className={s["filterPanelPeriod"]}>
        <Button
          type="bare"
          text="Сегодня"
          onClick={setToday}
          disabled={confirmInProgress}
        />
        <Button
          type="bare"
          text="Вчера"
          onClick={setYesterday}
          disabled={confirmInProgress}
        />
        <Button
          type="bare"
          text="Неделя"
          onClick={setWeek}
          disabled={confirmInProgress}
        />
        <Button
          type="bare"
          text="Месяц"
          onClick={setMonth}
          disabled={confirmInProgress}
        />
        <Button
          type="bare"
          text="Квартал"
          onClick={setQuarter}
          disabled={confirmInProgress}
        />
      </div>
      <div>
        <DatePicker
          dates={{
            startDate: data.date_start,
            endDate: data.date_end
          }}
          popupPosition="bottom"
          max={getToday()}
          onChangeDates={(values) => {
            values = dateValidation(values);
            onChangePeriod({
              date_start: values.startDate,
              date_end: values.endDate
            });
          }}
          isTimeAvailable={isTimeAvailable}
          isDisabled={confirmInProgress}
        />
      </div>
    </div>
  );
};

export default Period;
