import React, { FC, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  isEmpty,
  Button,
  Checkbox,
  SwitcherContainer,
  Icon,
  REQUIRED_FIELD_ERROR
} from "@omnichat/arm_ui_kit";

import {
  Input,
  SubTitle,
  FormField,
  FormFieldConnector,
  Select
} from "../../../../Components";

import { IFieldProps } from "./ClassifierFieldForm.d";

import TextTemplates from "../TextTemplates";
import TextValidates from "../TextValidates";

import * as s from "./style.module.scss";
import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";

const CB = SwitcherContainer(Checkbox);

const FIELD_TYPES_OPTIONS = [
  {
    value: "RADIO",
    label: "RADIO"
  },
  {
    value: "CHECKBOX",
    label: "CHECKBOX"
  },
  {
    value: "INPUT",
    label: "INPUT"
  },
  {
    value: "TEXTAREA",
    label: "TEXTAREA"
  },
  {
    value: "DROPDOWN",
    label: "DROPDOWN"
  }
];

const schema = Yup.object({
  name: Yup.string().required(REQUIRED_FIELD_ERROR),
  description: Yup.string(),
  isAlwaysShow: Yup.boolean().required(),
  type: Yup.string().required(REQUIRED_FIELD_ERROR)
});

const defaultValues = {
  name: "",
  description: "",
  isAlwaysShow: false,
  type: ""
};

export interface IFieldForm extends Yup.InferType<typeof schema> {}

const ClassifierFieldForm: FC<IFieldProps> = ({
  loading,
  data,
  templateData,
  validateData,
  links,
  errorMessage,
  onRemoveField,
  onRemoveLinks,
  onEditLink,
  onSave,
  onCreateLink,
  onSaveTemplate,
  onDeleteTemplate,
  onSaveValidate,
  onDeleteValidate
}): JSX.Element => {
  const methods = useForm<IFieldForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, watch } = methods;
  const isAlwaysShow = watch("isAlwaysShow");

  useEffect(() => {
    if (data) {
      reset({
        name: data.name || "",
        isAlwaysShow: data.isAlwaysShow || false,
        description: data.description || "",
        type: data.type || ""
      });
    } else {
      reset(defaultValues);
    }
  }, [data]);

  return (
    <div className={s["wrapper"]}>
      <FormProvider {...methods}>
        <SubTitle
          caption={data?.id ? "Редактирование поля" : "Создание поля"}
        />
        <FormFieldConnector
          name="name"
          label="Название"
          required
          Component={(p) => <Input {...p} />}
        />
        <FormFieldConnector
          name="isAlwaysShow"
          Component={(p) => (
            <CB
              {...p}
              type="slider"
              caption="Всегда отображать"
              checked={p.value}
            />
          )}
        />
        <FormFieldConnector
          name="description"
          label="Описание поля"
          Component={(p) => <Input {...p} />}
        />

        <FormFieldConnector
          required
          label="Тип поля"
          name="type"
          Component={(p) => (
            <>
              {data?.id ? (
                <Input {...p} disabled value={p.value} />
              ) : (
                <Select
                  {...p}
                  options={FIELD_TYPES_OPTIONS}
                  selected={FIELD_TYPES_OPTIONS.filter(o => o.value === p.value)}
                  onSelectOption={([o]) => p.onChange(o.value)}
                />
              )}
            </>
          )}
        />

        {!isAlwaysShow && data?.id && (
          <div className={s["linksContainer"]}>
            {!isEmpty(links) && (
              <FormField label={"Зависимости от значений предыдущих полей"}>
                <div className={s["links"]}>
                  {links?.map((l, i) => (
                    <div key={i} className={s["link"]}>
                      <div
                        className={s["linkName"]}
                        onClick={() => onEditLink?.(i)}
                      >
                        {l}
                      </div>
                      <Icon
                        name="cross"
                        color="blue"
                        width="20px"
                        height="20px"
                        onClick={() => onRemoveLinks(i)}
                      />
                    </div>
                  ))}
                </div>
              </FormField>
            )}

            <Button
              disabled={loading}
              type="underline"
              theme="default"
              text="Добавить зависимость"
              onClick={() => onCreateLink()}
            />
          </div>
        )}

        {["INPUT", "TEXTAREA"].includes(data?.type) && (
          <>
            <FormField label={"Шаблоны текста"}>
              <TextTemplates
                templates={templateData}
                onSaveTemplate={onSaveTemplate}
                onDeleteTemplate={onDeleteTemplate}
              />
            </FormField>
            <FormField label={"Валидация содержимого"}>
              <TextValidates
                validates={validateData}
                onSaveValidate={onSaveValidate}
                onDeleteValidate={onDeleteValidate}
              />
            </FormField>
          </>
        )}

        <div className={s["notification"]}>{errorMessage}</div>

        <div className={s["buttons"]}>
          <Button
            type="default"
            disabled={loading}
            theme="green"
            onClick={handleSubmit(
              (data) => onSave(data),
              ReactHookFormErrorHandler
            )}
            text="Сохранить"
          />
          {data?.id && (
            <Button
              type="default"
              disabled={loading}
              theme="red"
              onClick={() => onRemoveField()}
              text="Удалить"
            />
          )}
        </div>
      </FormProvider>
    </div>
  );
};

export default ClassifierFieldForm;
