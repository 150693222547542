// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".empty--IvK82d1aODMug5Ubftpx{text-align:center;margin-top:80px;color:#a0abb1}.loader--RioJQl6hbssedSDQ73d9{background:hsla(0,0%,100%,.4509803922);position:absolute;top:0;left:0;width:100%;height:100%;z-index:100}", "",{"version":3,"sources":["webpack://./frontend/src/Dashboard/Appeals/style.module.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAA,CACA,eAAA,CACA,aAAA,CAGF,8BACE,sCAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA","sourcesContent":[".empty {\n  text-align: center;\n  margin-top: 80px;\n  color: #a0abb1;\n}\n\n.loader {\n  background: #ffffff73;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 100;\n}"],"sourceRoot":""}]);
// Exports
export var empty = "empty--IvK82d1aODMug5Ubftpx";
export var loader = "loader--RioJQl6hbssedSDQ73d9";
export default ___CSS_LOADER_EXPORT___;
